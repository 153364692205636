import { useResponsiveBreakpoints } from "../../utils/useResponsiveBreakpoints";
import { createEffect, ErrorBoundary, lazy, ParentProps, Show } from "solid-js";
import { useNavigate } from "@solidjs/router";
import MobileLayout from "./MobileLayout";
import { useAuth } from "../auth/authContext";
import { UnauthorizedError } from "../../api/utils";
import { NotificationsProvider } from "../notifications";

/* Lazy load DesktopLayout (but not MobileLayout) as most users will use the mobile app or website,
 * and users with 3G Internet connection are the most impacted in terms of loading time.
 */
const DesktopLayout = lazy(() => import("./DesktopLayout"));

export default function ProtectedRouteWrapper(props: ParentProps) {
    return (
        <ProtectedRouteGuard>
            <NotificationsProvider>
                <ProtectedRouteLayout>{props.children}</ProtectedRouteLayout>
            </NotificationsProvider>
        </ProtectedRouteGuard>
    );
}

function ProtectedRouteGuard(props: ParentProps) {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    createEffect(() => {
        if (isAuthenticated() === false) {
            console.debug("Unauthorized user within protected route, redirecting to sign in");
            navigate("/login", { replace: true });
        }
    });

    return (
        <ErrorBoundary
            fallback={error => {
                if (error instanceof UnauthorizedError) {
                    console.debug(
                        "Ignored unauthorized endpoint call within protected route as user should be redirected soon",
                    );
                    return null;
                } else throw error;
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}

function ProtectedRouteLayout(props: ParentProps) {
    const { lg } = useResponsiveBreakpoints();

    return (
        <Show when={lg()} fallback={<MobileLayout>{props.children}</MobileLayout>}>
            <DesktopLayout>{props.children}</DesktopLayout>
        </Show>
    );
}
