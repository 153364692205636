import { useLocale } from "../../../i18n/context";
import { Match, ParentProps, Show, Switch } from "solid-js";
import { ErrorMessage } from "./ErrorMessage";
import { FieldProps } from "./types";
import { FieldController, useFormState } from "../../state";
import RichTextRender from "../../../../utils/rich-text/RichTextRender";

const labelClass = "font-normal";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LabelLayoutProps<TElement> = ParentProps<FieldProps<any, TElement>> & {
    /** The id of the HTML input that this layout is wrapping, used to focus
     * the input when clicking the label.
     *
     * @example
     * function MyField(props: FieldProps<...>) {
     *    const field = createField(...);
     *    const inputId = createUniqueId();
     *
     *    return (
     *        <LabelAbove {...props} field={field} inputId={inputId}>
     *            ...
     *                <input {...getCommonInputProps(inputId, props)} />
     *            ...
     *        </LabelAbove>
     *    );
     * }
     *
     * @remarks
     * Fields with multiple inputs, like radio buttons, require creating a
     * unique `inputId` for each input, or clicking the labels will always
     * focus the first option.
     */
    field: FieldController<unknown>;
    inputId: string;
    parentClass?: string;
    errorClass?: string;
    description?: string | null;
};
function DescriptionLabel<TElement>(props: LabelLayoutProps<TElement>) {
    return (
        <Show when={props.description}>
            {description => (
                <div class="text-sm text-dark-gray-500">
                    <RichTextRender text={description()} />
                </div>
            )}
        </Show>
    );
}
export function LabelAbove<TElement>(props: LabelLayoutProps<TElement>) {
    return (
        <FieldWrapper class={`flex w-full flex-col ${props.parentClass ?? ""}`}>
            {props.label && (
                <label for={props.inputId} class={`${labelClass} ${props.labelClass}`}>
                    {props.label} <RenderOptionalityIndicator optional={props.optional} />
                </label>
            )}
            <DescriptionLabel {...props} />
            {props.children}
            <ErrorMessage field={props.field} class={props.errorClass} />
        </FieldWrapper>
    );
}

export function LabelAfter<TElement>(props: LabelLayoutProps<TElement>) {
    return (
        <FieldWrapper class={`flex flex-col gap-1 ${props.parentClass ?? ""}`}>
            <div class="flex items-center gap-1">
                {props.children}
                <label for={props.inputId} class={`${labelClass} ${props.labelClass}`}>
                    {props.label}
                </label>
            </div>
            <DescriptionLabel {...props} />
        </FieldWrapper>
    );
}

export function LabelParent<TElement>(props: LabelLayoutProps<TElement>) {
    return (
        <FieldWrapper>
            <label
                for={props.inputId}
                class={`flex items-center gap-1 ${labelClass} ${props.labelClass}`}
            >
                <span>{props.label}</span>
                <DescriptionLabel {...props} />
                {props.children}
            </label>
            <ErrorMessage field={props.field} class={props.errorClass} />
        </FieldWrapper>
    );
}

function FieldWrapper(props: ParentProps<{ class?: string }>) {
    const form = useFormState();
    const fieldWrapperClass = () => form.state.fieldWrapperClass();
    return <div class={`${props.class} ${fieldWrapperClass()}`}>{props.children}</div>;
}

function RenderOptionalityIndicator(props: { optional: boolean | undefined }) {
    const [locale] = useLocale();
    const form = useFormState();
    const indicator = () => form.state.optionalityIndicator();
    return (
        <Switch>
            <Match when={indicator() === "optional"}>
                <Show when={props.optional}>({locale().personalData.optional})</Show>
            </Match>
            <Match when={indicator() === "asterisk"}>
                <Show when={!props.optional}>
                    <span class="text-[red]">*</span>
                </Show>
            </Match>
        </Switch>
    );
}
